import { createStore, createEvent } from 'effector';

export const setBaseUserInfo = createEvent('set base informations for user');
export const setCourseInfo = createEvent('set course informations');
export const setReservationInfo = createEvent('set reservation informations');
export const setSaleTotalValue = createEvent('set course sale total value');

let MBAcademy = (function() {
  let instance;

  return function(
    defaultValue = {
      formType: null,
      modality: null,
      name: null,
      cpf: null,
      cnpj: null,
      email: null,
      phone: null,
      userUf: null,
      userCity: null,
      neighborhood: null,
      address: null,
      cep: null,
      mailing: null,
      whatsapping: null,
      phoning: null,
      courseDateTime: null,
      uf: null,
      city: null,
      subsidiary: null,
      numberOfVacancies: null,
      optingSimpleNational: null,
      stateRegistration: null,
      municipalInscription: null,
      dataPermissions: null,
      acceptAdhesionForm: null,
      saleID: null,
      courseTitle: null,
      workload: null,
      courseValue: null,
      saleTotalValue: null
    }
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setBaseUserInfo, (state, baseUserInfo) => ({
        ...state,
        formType: baseUserInfo.formType,
        modality: baseUserInfo.modality,
        name: baseUserInfo.name,
        cpf: baseUserInfo.cpf,
        cnpj: baseUserInfo.cnpj,
        email: baseUserInfo.email,
        phone: baseUserInfo.phone,
        userUf: baseUserInfo.userUf,
        userCity: baseUserInfo.userCity,
        neighborhood: baseUserInfo.neighborhood,
        address: baseUserInfo.address,
        cep: baseUserInfo.cep,
        mailing: baseUserInfo.mailing,
        phoning: baseUserInfo.phoning,
        whatsapping: baseUserInfo.whatsapping
      }));

      instance.on(setReservationInfo, (state, setReservationInfo) => ({
        ...state,
        courseDateTime: setReservationInfo.courseDateTime,
        uf: setReservationInfo.uf,
        city: setReservationInfo.city,
        subsidiary: setReservationInfo.subsidiary,
        numberOfVacancies: setReservationInfo.numberOfVacancies,
        optingSimpleNational: setReservationInfo.optingSimpleNational,
        stateRegistration: setReservationInfo.stateRegistration,
        municipalInscription: setReservationInfo.municipalInscription,
        dataPermissions: setReservationInfo.dataPermissions,
        acceptAdhesionForm: setReservationInfo.acceptAdhesionForm
      }));

      instance.on(setCourseInfo, (state, courseInfo) => ({
        ...state,
        courseTitle: courseInfo.courseTitle,
        workload: courseInfo.workload,
        courseValue: courseInfo.courseValue
      }));

      instance.on(setSaleTotalValue, (state, courseInfo) => ({
        ...state,
        saleTotalValue: courseInfo.saleTotalValue
      }));
    }

    return instance;
  };
})();

export default MBAcademy;
